<template>
  <div class="myTender">
    <TabPanes @tapHandleClick="tapHandleClick" @searchClick="searchClick" :tabsList="tabsList" :activeName="activeName" />
    <ProjectList :redDot="'redDot'" :list="projectDataList" :marginRight="20" :windowWidth="900" />
    <Pagination v-if="projectDataList.length" />
  </div>
</template>

<script>
import TabPanes from '@/components/common/tabPanes'
import ProjectList from '@/components/common/projectList'
import Pagination from '@/components/common/pagination'
import {myTenderList, statistics} from '@/api/user/user'
export default {
  name: 'myTender',
  components: {
    TabPanes,
    ProjectList,
    Pagination
  },
  data() {
    return {
      projectDataList: [],
      tabsList: [
        {
          titel: '待选标',
          quantity: '1'
        },
        {
          titel: '未中标',
          quantity: '2'
        },
        {
          titel: '已中标',
          quantity: '3'
        }],
      activeName: '待选标',
      status: 0,
      totalCount: 0,
      keyword: '',
      page: 1
    }
  },
  mounted() {
    this.project()
    this.statisticsObj()
  },
  methods: {
    statisticsObj() {
      statistics().then(res => {
        this.tabsList[0].quantity = res.data.tender_data.tender_to_be_selected
        this.tabsList[1].quantity = res.data.tender_data.tender_not_won_the_bid
        this.tabsList[2].quantity = res.data.tender_data.tender_has_won_the_bid
        // console.log('打印', res.data)
      })
    },
    searchClick(input) { // 搜索事件
      this.keyword = input
      // console.log(input)、
      this.project()
    },
    tapHandleClick(index, label) { // tap的切换
      // console.log(index)
      if (label === '待选标') {
        this.status = 0
      } else if (label === '未中标') {
        this.status = 2
      } else {
        this.status = 1
      }
      this.project()
    },
    changeCurrentPage(value) {
      this.page = value
      this.project()
    },
    project() {
      const data = {
        keyword: this.keyword,
        psize: 16,
        page: this.page,
        status: this.status
      }
      myTenderList(data).then(res => {
        this.totalCount = res.data.count
        this.projectDataList = res.data.list
        // console.log('打印', res.data.list)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .myTender{
    padding: 32px 24px;
    background: #fff;
    margin-top: 12px;
    min-height: 583px;
  }
</style>
